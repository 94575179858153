import { useState } from "react";
import logo from "./logo.svg";
import background from "./background-1.png";
import "./App.css";
import { Wheel } from "react-custom-roulette";

const data = [
  {
    option: "REPETIR",
    style: { backgroundColor: "black", textColor: "white" },
  },
  {
    option: "R$ 20,00",
    style: { backgroundColor: "teal", textColor: "white", fontSize: 30 },
    optionSize: 1,
  },
  {
    option: "R$ 300,00",
    style: { backgroundColor: "white", textColor: "black" },
  },
  {
    option: "R$ 500,00",
    style: { backgroundColor: "green", textColor: "white" },
  },
  {
    option: "REPETIR",
    style: { backgroundColor: "black", textColor: "white" },
  },
  {
    option: "R$ 20,00",
    style: { backgroundColor: "teal", textColor: "white", fontSize: 30 },
    optionSize: 1,
  },
  {
    option: "R$ 300,00",
    style: { backgroundColor: "white", textColor: "black" },
  },
  {
    option: "R$ 500,00",
    style: { backgroundColor: "green", textColor: "white" },
  },
];

function App() {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [startingNumber, setStartingNumber] = useState(0);
  const [showPrize, setShowPrize] = useState(false);
  const handleSpinClick = () => {
    if (!mustSpin) {
      //const newPrizeNumber = Math.floor(Math.random() * data.length);
      const newPrizeNumber = 7;
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
    }
  };

  const handleEsportiva = () => {
    window.location.href = "https://esportiva.bet?ref=c33289281582"
  }
  return (
    <div className="App">
      <header
        className="App-header"
        style={{ backgroundImage: `url(${background})`, backgroundSize:"cover" }}
      >
        {!showPrize && (<Wheel
          mustStartSpinning={mustSpin}
          spinDuration={2}
          prizeNumber={prizeNumber}
          data={data}
          backgroundColors={["#3e3e3e", "#df3428"]}
          radiusLineColor={"black"}
          innerBorderColor={"#FFFFFF"}
          outerBorderColor={"yellow"}
          textColors={["#ffffff"]}
          onStopSpinning={() => {
            //alert("PARABÉNS você tirou " + data[prizeNumber].option);
            setMustSpin(false);
            setStartingNumber(prizeNumber);
            setTimeout(() => {
              setShowPrize(true);
            }, 3000)
            
          }}
          startingOptionIndex={startingNumber}
          disableInitialAnimation={false}
        />)}
        {!mustSpin && prizeNumber === 0 && (
          <button className="button-cta" onClick={handleSpinClick}>
            GIRAR ROLETA
          </button>
        )}

        {showPrize && (
          <>
          PARABÉNS VOCÊ GANHOU <div style={{
            margin:"1em",
            fontSize:44,
            fontWeight:"bold"
          }}>R$ 500,00!</div>
          <button className="button-cta" onClick={handleEsportiva}>
            CLIQUE AQUI PARA RECEBER  
          </button> 
          Saque seu Bônus através do SMS ou E-MAIL após finalizar o cadastro na Esportiva.Bet    
          </>
        )}
      </header>
    </div>
  );
}

export default App;
